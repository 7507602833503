import React, { useEffect, useState } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/pagination';
import { USERDATA } from '../data/user-data';
import carteira from '../assets/registo de horas.svg';
import carteira1 from '../assets/registo de horas li.svg';
import teste from '../assets/testeteste.svg';
import WidgetTabeladeTokens from '../components/widget/widget-tabela-tokens';
import { Link } from 'react-router-dom';
import { TfiArrowRight } from 'react-icons/tfi';
import { TbCoinBitcoin, TbCoins } from 'react-icons/tb';
import { BiTransfer } from 'react-icons/bi';
import { Pagination } from 'swiper/modules';




export default function Inicio({ darkMode, isTabletMobile }) {

    const [userperfilData, setUserPerfilData] = useState([]);

    useEffect(() => {
        setUserPerfilData(USERDATA.useralldata[0] || []);
    }, []);


    const content = [
        {
            icon: <TbCoinBitcoin className='icon-menu mb-3' />,
            title: 'Tokens',
            description:
                'Display all tokens in the cryptocurrency market, sorted by their market value.',
        },
        {
            icon: <TbCoins className='icon-menu mb-3' />,
            title: 'Best Holders',
            description:
                'Show all wallets of a token, sorted by the wallet with the most tokens or the highest total wallet value.',
        },
        {
            icon: <BiTransfer className='icon-menu mb-3' />,
            title: 'Transactions',
            description: 'Show all transactions from a selected wallet.',
        },
    ];


    return (
        <>
            {isTabletMobile ? (
                <>
                    <div className='w-full flex justify-center items-center'>
                        <div className='grid gap-4 grid-cols-1 w-full h-full'>
                            <div className='grid grid-cols-1 gap-4'>
                                <div className='card'>
                                    <div className="w-full h-full">
                                        <h1 className='text-base title-cinza mb-2'>Welcome, <span className='italic'>{userperfilData.name}</span></h1>
                                        <h1 className='text-2xl font-extrabold'>Dashboard</h1>
                                    </div>
                                </div>
                            </div>
                            <div className=''>
                                <div className='w-full'>
                                    <Swiper
                                        // slidesPerView={1}
                                        // spaceBetween={20}
                                        // centeredSlides={true}
                                        // pagination={Pagination}
                                        // loop={true}
                                        // className="mySwiper"
                                        // autoplay={{
                                        //     delay: 2000, // Define o atraso em milissegundos entre as transições de slide
                                        // }}
                                        slidesPerView={'auto'}
                                        centeredSlides={true}
                                        spaceBetween={30}
                                        pagination={{
                                            clickable: true,
                                        }}
                                        modules={[Pagination]}
                                        className="mySwiper"
                                    >
                                        <SwiperSlide>
                                            <div className='card-green'>
                                                <div className="card-info-block">
                                                    <TbCoinBitcoin className='icon-menu mb-3' />
                                                    <p className='font-bold'>Tokens</p>
                                                    <p className='text-base'>Display all tokens in the cryptocurrency market, sorted by their market value.</p>
                                                </div>
                                            </div>
                                        </SwiperSlide>
                                        <SwiperSlide>
                                            <div className='card-green'>
                                                <div className="card-info-block">
                                                    <TbCoins className='icon-menu mb-3' />
                                                    <p className='font-bold'>Best Holders</p>
                                                    <p className='text-base'>Show all wallets of a token, sorted by the wallet with the most tokens or the highest total wallet value.</p>
                                                </div>
                                            </div>
                                        </SwiperSlide>
                                        <SwiperSlide>
                                            <div className='card-green'>
                                                <div className="card-info-block">
                                                    <BiTransfer className='icon-menu mb-3' />
                                                    <p className='font-bold'>Transactions</p>
                                                    <p className='text-base'>Show all transactions from a selected wallet.</p>
                                                </div>
                                            </div>
                                        </SwiperSlide>
                                    </Swiper>
                                </div>
                            </div>
                            <div className='card'>
                                <div className='flex flex-wrap justify-between'>
                                    <div>
                                        <p className='font-bold'>Main Portfolio</p>
                                    </div>
                                    <div className='flex'>
                                        <Link className='togglemodethemewidget' to="/perfil">
                                            <TfiArrowRight className='icon-menu' />
                                        </Link>
                                    </div>
                                </div>
                                <br></br>
                                <div>
                                    <img src={darkMode ? carteira : carteira1} alt="OPENWALLET" />
                                </div>
                                <br></br>
                            </div>
                            <div className='grid gap-4 grid-cols-1 w-full h-full'>
                                <div className='grid grid-cols-1 gap-4'>
                                    <div className='card'>
                                        <WidgetTabeladeTokens isTabletMobile={isTabletMobile} />
                                    </div>
                                </div>
                            </div>
                            <div className='card info-card'>
                                <br></br>
                                <div className='text-center'>
                                    <img className='text-center flex justify-center' src={teste} alt="OPENWALLET" />
                                </div>
                                <br></br>
                                <div className='text-center flex flex-col justify-center gap-4'>
                                    <p className='font-extrabold text-center'>Go More with a Pro</p>
                                    <p className='text-center'>Advanced features like a pro to increase your profit!</p>
                                    <div>
                                        <button className='btn-default'>
                                            More Details
                                        </button>
                                    </div>
                                </div>
                                <br></br>
                            </div>
                        </div>
                    </div>
                </>
            ) : (
                <>
                    <div className='w-full flex flex-col justify-center items-center gap-4'>
                        <div className='grid gap-4 grid-cols-1 w-full h-full'>
                            <div className='grid grid-cols-1 gap-4'>
                                <div className='card'>
                                    <div className="">
                                        <h1 className='text-base title-cinza mb-2'>Welcome, <span className='italic'>{userperfilData.name}</span></h1>
                                        <h1 className='text-2xl font-extrabold'>Dashboard</h1>
                                    </div>
                                </div>
                            </div>
                            <div className='grid grid-cols-3 gap-4 w-full h-full'>
                                <div className='grid grid-cols-1 gap-4'>
                                    <div className='card-green'>
                                        <div className="card-info-block">
                                            <TbCoinBitcoin className='icon-menu mb-3' />
                                            <p className='font-bold'>Tokens</p>
                                            <p className='text-base'>Display all tokens in the cryptocurrency market, sorted by their market value.</p>
                                        </div>
                                    </div>
                                </div>
                                <div className='grid grid-cols-1 gap-4'>
                                    <div className='card-green'>
                                        <div className="card-info-block">
                                            <TbCoins className='icon-menu mb-3' />
                                            <p className='font-bold'>Best Holders</p>
                                            <p className='text-base'>Show all wallets of a token, sorted by the wallet with the most tokens or the highest total wallet value.</p>

                                        </div>
                                    </div>
                                </div>
                                <div className='grid grid-cols-1 gap-4'>
                                    <div className='card-green'>
                                        <div className="card-info-block">
                                            <BiTransfer className='icon-menu mb-3' />
                                            <p className='font-bold'>Transactions</p>
                                            <p className='text-base'>Show all transactions from a selected wallet.</p>

                                        </div>
                                    </div>
                                </div>
                            </div>
                            {/* <div className='grid grid-cols-1 gap-4'>
                                <div className='card'>
                                    <div className="">
                                        <h1 className='text-lg'>Welcome, <span className='text-2xl font-extrabold italic'>{userperfilData.name}</span></h1>
                                    </div>
                                </div>
                            </div> */}
                        </div>
                        <div className='flex gap-4 w-full h-full'>
                            <div className='card w-4/5'>
                                <div className='flex flex-wrap justify-between'>
                                    <div>
                                        <p className='font-bold'>Main Portfolio</p>
                                    </div>
                                    <div className='flex'>
                                        <Link className='togglemodethemewidget' to="/perfil">
                                            <TfiArrowRight className='icon-menu' />
                                        </Link>
                                    </div>
                                </div>
                                <br></br>
                                <div className='flex justify-center'>
                                    <img src={darkMode ? carteira : carteira1} alt="OPENWALLET" />
                                </div>
                                <br></br>
                            </div>
                            <div className='w-1/5 card flex flex-col justify-center items-center gap-4'>
                                <div className='flex justify-center'>
                                    <img className='text-center flex justify-center' src={teste} alt="OPENWALLET" />
                                </div>
                                <br></br>
                                <p className='font-bold text-center'>More with a Pro</p>
                                <p className='text-center'>Advanced features like a pro to increase your profit!</p>
                                <div>
                                    <button className='btn-default'>
                                        More Details
                                    </button>
                                </div>
                            </div>
                        </div>
                        <div className='grid gap-4 grid-cols-1 w-full h-full'>
                            <div className='grid grid-cols-1 gap-4'>
                                <div className='card'>
                                    <WidgetTabeladeTokens />
                                </div>
                            </div>
                        </div>
                    </div >
                </>
            )}
        </>
    )
}   