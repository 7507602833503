import React, { useEffect, useState } from 'react';
import { Input, Tooltip } from 'antd';
import { TOKENSDATAWIDGET } from '../../data/obter-dados-tokens-widget';
import CopyButton from '../../components/copybutton';
import { UserOutlined } from '@ant-design/icons';
import { Link, useParams } from 'react-router-dom';
import { TfiArrowRight } from 'react-icons/tfi';
import { TbCoins } from 'react-icons/tb';


export default function WidgetTabeladeTokens({ darkMode, isTabletMobile }) {


    const [searchQuery, setSearchQuery] = useState('');
    const [tokensData, setTokensData] = useState([]);

    useEffect(() => {
        setTokensData(TOKENSDATAWIDGET.tokenall || []);
    }, []);

    const searchFilterDay = tokensData.filter((token) => {
        const fullName = token.nametoken;
        const searchQueryLowerCase = searchQuery.toLowerCase();
        return fullName.toLowerCase().includes(searchQueryLowerCase);
    });

    const handleInputChange = (event) => {
        setSearchQuery(event.target.value);
    };

    return (
        <>
            {isTabletMobile ? (
                // Responsive
                <>
                    <div className='flex flex-wrap justify-between mb-6'>
                        <div>
                            <p className='font-bold'>Market Trends</p>
                        </div>
                        <div className='flex'>
                            <Link className='togglemodethemewidget' to="/perfil">
                                <TfiArrowRight className='icon-menu' />
                            </Link>
                        </div>
                    </div>
                    <div className="w-full">
                        <div className="">
                            <div className="flex mb-5 gap-4 titulosdatabela justify-between">
                                <div>
                                    <div className="flex-1 font-semibold text-xs p-0 m-0"># - Name</div>
                                    <div className="flex-1 font-semibold text-xs p-0 m-0">Address</div>
                                </div>
                                <div>
                                    <div className="flex-1 font-semibold text-xs text-right p-0 m-0">Number Holders</div>
                                    <div className="flex-1 font-semibold text-xs text-right p-0 m-0">Value</div>
                                </div>
                            </div>
                            {searchFilterDay.map((token, index) => (
                                <div
                                    key={index}
                                    className={`mb-4 border-b border-slate-700 items-center cursor-pointer transition-default row-flex items-stretch mb-2 ${index % 2 === 0 ? 'bg-dark-sexta' : 'bg-dark-sexta'}`}
                                >
                                    <div className='flex gap-4 items-center justify-between h-14 p-4'>
                                        <div className='w-3/6'>
                                            <div className="tablet:flex flex-1 text-xs text-destaque"><Link to={`/best-holders`}>#{token.id} - {token.nametoken}</Link></div>
                                            <div className="tablet:flex flex-1 text-xs">
                                                <Tooltip placement="topRight" title={token.addresstoken}>
                                                    {token.addresstoken.slice(0, 5)}...{token.addresstoken.slice(-5)}
                                                </Tooltip>
                                                <CopyButton text={token.addresstoken} />
                                            </div>
                                        </div>
                                        <div className='w-3/6 text-end'>
                                            <div className="tablet:flex flex-1 text-xs">{token.numberholders}</div>
                                            <div className="tablet:flex flex-1 text-sm text-destaque flex items-center gap-2 justify-end">{token.price} <TbCoins className='icon-menu' /></div>
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                </>
            ) : (
                <>
                    <div className='flex flex-wrap justify-between mb-6'>
                        <div>
                            <p className='font-bold'>Market Trends</p>
                        </div>
                        <div className='flex'>
                            <Link className='togglemodethemewidget' to="/perfil">
                                <TfiArrowRight className='icon-menu' />
                            </Link>
                        </div>
                    </div>
                    <div className="w-full">
                        <div className="">
                            <div className="flex mb-5 gap-4 titulosdatabela">
                                <div className="flex-1 py-2 font-semibold text-sm"># - Name</div>
                                <div className="flex-1 py-2 font-semibold text-sm">Price</div>
                                <div className="flex-1 py-2 font-semibold text-sm">Market Cap</div>
                                <div className="flex-1 py-2 font-semibold text-sm">Number Holders</div>
                                <div className="flex-1 py-2 font-semibold text-sm">Last 24h</div>
                            </div>
                            {searchFilterDay.map((token, index) => (
                                <div
                                    key={index}
                                    className={`linha-info mb-4 border-b border-slate-700 items-center cursor-pointer transition-default row-flex items-stretch mb-2 ${index % 2 === 0 ? 'bg-dark-sexta' : 'bg-dark-sexta'}`}
                                >
                                    <div className='flex gap-4 items-center justify-between'>
                                        <div className="tablet:flex flex-1 py-2 text-base">
                                            <Link to={`/best-holders`}>#{token.id} - {token.nametoken}</Link>
                                        </div>
                                        <div className="tablet:flex flex-1 py-2 text-base">{token.price}</div>
                                        <div className="tablet:flex flex-1 py-2 text-base">{token.marketcap}</div>
                                        <div className="tablet:flex flex-1 flex items-center gap-2 py-2 text-base">{token.numberholders} <UserOutlined className='icon-menu' /></div>
                                        <div className="tablet:flex flex-1 py-2 text-base"><img src={token.imageURL} /></div>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                </>
            )}

        </>
    );
}
