// import React, { useEffect, useState } from 'react';
// import { Input, Tooltip } from 'antd';
// import { WALLETSDATA } from '../obter-dados-wallets';
// import CopyButton from '../../components/copybutton';
// import { TbCoins } from 'react-icons/tb';
// import { BiWallet } from 'react-icons/bi';

// export default function TabeladeWallets({ darkMode, isTabletMobile }) {
//     const [searchQuery, setSearchQuery] = useState('');
//     const [walletsData, setWalletsData] = useState([]);
//     const [sortOrder, setSortOrder] = useState('asc');

//     useEffect(() => {
//         setWalletsData(WALLETSDATA.walletsall || []);
//         console.log(walletsData)
//     }, []);

//     useEffect(() => {
//         const sorted = sortData(sortOrder);
//         setWalletsData(sorted);
//     }, [searchQuery, sortOrder]);

//     useEffect(() => {
//         console.log(walletsData); // Verifique os dados após a atualização do estado
//     }, [walletsData]);



//     const handleInputChange = (event) => {
//         setSearchQuery(event.target.value);
//     };

//     const toggleSortOrder = () => {
//         const newOrder = sortOrder === 'asc' ? 'desc' : 'asc';
//         setSortOrder(newOrder);
//     };

//     const filteredData = walletsData.filter((wallet) => {
//         const fullName = wallet.wallet;
//         const searchQueryLowerCase = searchQuery.toLowerCase();
//         return fullName.toLowerCase().includes(searchQueryLowerCase);
//     });

//     const sortData = (order) => {
//         const sorted = [...walletsData];
//         sorted.sort((a, b) => {
//             if (order === 'desc') {
//                 return a.value - b.value;
//             } else {
//                 return b.value - a.value;
//             }
//         });
//         return sorted;
//     };

//     return (
//         <>
//             {isTabletMobile ? (
//                 // Responsive
//                 <>
//                     <div className="">
//                         <Input
//                             type="text"
//                             size="large"
//                             className="input-geral"
//                             placeholder="Search by Wallet Address..."
//                             value={searchQuery}
//                             onChange={handleInputChange}
//                         />
//                     </div>
//                     <br></br>
//                     <div className='flex justify-end'>
//                         <button
//                             className="sorting-button p-2"
//                             onClick={toggleSortOrder}
//                         >
//                             Value {sortOrder === 'asc' ? '▼' : '▲'}
//                         </button>
//                     </div>
//                     <br></br>
//                     <div className="w-full">
//                         <div className="">
//                             <div className="flex mb-5 gap-4 titulosdatabela justify-between">
//                                 <div>
//                                     <div className="flex-1 font-semibold text-sm p-0 m-0">Position - Best Token Hold</div>
//                                     <div className="flex-1 font-semibold text-sm p-0 m-0">Wallet</div>
//                                 </div>
//                                 <div>
//                                     <div className="flex-1 font-semibold text-sm text-right p-0 m-0">Nº Tokens</div>
//                                     <div className="flex-1 font-semibold text-sm text-right p-0 m-0">Value $</div>
//                                 </div>
//                             </div>
//                             {filteredData.map((wallet, index) => (
//                                 <div
//                                     key={index}
//                                     className={`mb-4 border-b border-slate-700 items-center cursor-pointer transition-default row-flex items-stretch mb-2 ${index % 2 === 0 ? 'bg-dark-sexta' : 'bg-dark-sexta'}`}
//                                 >
//                                     <div className='flex gap-4 items-center justify-between h-14 p-4'>
//                                         <div className='w-3/6'>
//                                             <div className="tablet:flex flex-1 text-sm text-destaque"># {wallet.position} - {wallet.besthold}</div>
//                                             <div className="tablet:flex flex-1 text-sm">
//                                                 <Tooltip placement="topRight" title={wallet.wallet}>
//                                                     {wallet.wallet.slice(0, 5)}...{wallet.wallet.slice(-5)}
//                                                 </Tooltip>
//                                                 <CopyButton text={wallet.wallet} />
//                                             </div>
//                                         </div>
//                                         <div className='w-3/6 text-end'>
//                                             <div className="tablet:flex flex-1 text-sm">{wallet.numbertokens}</div>
//                                             <div className="tablet:flex flex-1 text-lg text-destaque flex items-center gap-2 justify-end">${wallet.value} <TbCoins className='icon-menu' /></div>
//                                         </div>
//                                     </div>
//                                 </div>
//                             ))}
//                         </div>
//                     </div>
//                 </>
//             ) : (
//                 <>
//                     <div className="">
//                         <Input
//                             type="text"
//                             size="large"
//                             className="input-geral"
//                             placeholder="Search by Wallet Address..."
//                             value={searchQuery}
//                             onChange={handleInputChange}
//                         />
//                     </div>
//                     <br></br>
//                     <div className='flex justify-end'>
//                         <button
//                             className="sorting-button p-2"
//                             onClick={toggleSortOrder}
//                         >
//                             Value {sortOrder === 'asc' ? '▼' : '▲'}
//                         </button>
//                     </div>
//                     <br></br>
//                     <div className="w-full">
//                         <div className="">
//                             <div className="flex mb-5 gap-4 titulosdatabela">
//                                 <div className="flex-1 py-2 font-semibold text-base">Position - Wallet</div>
//                                 <div className="flex-1 py-2 font-semibold text-base">Best Token Hold</div>
//                                 <div className="flex-1 py-2 font-semibold text-base">Nº Tokens</div>
//                                 <div className="flex-1 py-2 font-semibold text-base">Value $</div>
//                             </div>
//                             {filteredData.map((wallet, index) => (
//                                 <div
//                                     key={index}
//                                     className={`linha-info mb-4 border-b border-slate-700 items-center cursor-pointer transition-default row-flex items-stretch mb-2 ${index % 2 === 0 ? 'bg-dark-sexta' : 'bg-dark-sexta'}`}
//                                 >
//                                     <div className='flex gap-4 items-center justify-between'>
//                                         <div className="tablet:flex flex-1 py-2 text-base">
//                                             <Tooltip placement="topRight" title={wallet.wallet}>
//                                                 # {wallet.position} - {wallet.wallet.slice(0, 5)}...{wallet.wallet.slice(-5)}
//                                             </Tooltip>
//                                             <CopyButton text={wallet.wallet} />
//                                         </div>
//                                         <div className="tablet:flex flex-1 py-2 text-base">{wallet.besthold}</div>
//                                         <div className="tablet:flex flex-1 flex items-center gap-2 py-2 text-base">{wallet.numbertokens} <BiWallet className='icon-menu' /> </div>
//                                         <div className="tablet:flex flex-1 flex items-center gap-2 py-2 text-base text-destaque">$ {wallet.value} <TbCoins className='icon-menu' /></div>
//                                     </div>
//                                 </div>
//                             ))}
//                         </div>
//                     </div>
//                 </>
//             )}

//         </>
//     );
// }


import React, { useEffect, useState } from 'react';
import { Input, Tooltip } from 'antd';
import { WALLETSDATA } from '../obter-dados-wallets';
import CopyButton from '../../components/copybutton';
import { TbCoins } from 'react-icons/tb';
import { Link } from 'react-router-dom';

export default function TabeladeWallets({ darkMode, isTabletMobile }) {
    const [searchQuery, setSearchQuery] = useState('');
    const [walletsData, setWalletsData] = useState([]);
    const [sortOrder, setSortOrder] = useState('asc');

    useEffect(() => {
        setWalletsData(WALLETSDATA.walletsall || []);
        console.log(walletsData)
    }, []);

    const searchFilterDay = walletsData.filter((wallet) => {
        const fullName = wallet.wallet;
        const searchQueryLowerCase = searchQuery.toLowerCase();
        return fullName.toLowerCase().includes(searchQueryLowerCase);
    });

    const handleInputChange = (event) => {
        setSearchQuery(event.target.value);
    };


    // const toggleSortOrder = () => {
    //     const newOrder = sortOrder === 'asc' ? 'desc' : 'asc';
    //     setSortOrder(newOrder);
    // };


    // const sortData = (order) => {
    //     const sorted = [...walletsData];
    //     sorted.sort((a, b) => {
    //         if (order === 'desc') {
    //             return a.value - b.value;
    //         } else {
    //             return b.value - a.value;
    //         }
    //     });
    //     return sorted;
    // };





    return (
        <>
            {isTabletMobile ? (
                // Responsive
                <>
                    <div className="">
                        <Input
                            type="text"
                            size="large"
                            className="input-geral"
                            placeholder="Search by Wallet Address..."
                            value={searchQuery}
                            onChange={handleInputChange}
                        />
                    </div>
                    <br></br>
                    <div className="w-full">
                        <div className="">
                            <div className="flex mb-5 gap-4 titulosdatabela justify-between">
                                <div>
                                    <div className="flex-1 font-semibold text-xs p-0 m-0">Position - Best Token Hold</div>
                                    <div className="flex-1 font-semibold text-xs p-0 m-0">Wallet</div>
                                </div>
                                <div>
                                    <div className="flex-1 font-semibold text-xs text-right p-0 m-0">Nº Tokens</div>
                                    <div className="flex-1 font-semibold text-xs text-right p-0 m-0">Value $</div>
                                </div>
                            </div>
                            {searchFilterDay.map((wallet, index) => (
                                <div
                                    key={index}
                                    className={`mb-4 border-b border-slate-700 items-center cursor-pointer transition-default row-flex items-stretch mb-2 ${index % 2 === 0 ? 'bg-dark-sexta' : 'bg-dark-sexta'}`}
                                >
                                    <Link to={`/transactions`}>
                                        <div className='flex gap-4 items-center justify-between h-14 linhasdatabela'>
                                            <div className='w-3/6'>
                                                <div className="tablet:flex flex-1 text-xs text-destaque"># {wallet.position} - {wallet.besthold}</div>
                                                <div className="tablet:flex flex-1 text-xs">
                                                    <Tooltip placement="topRight" title={wallet.wallet}>
                                                        {wallet.wallet.slice(0, 5)}...{wallet.wallet.slice(-5)}
                                                    </Tooltip>
                                                    <CopyButton text={wallet.wallet} />
                                                </div>
                                            </div>
                                            <div className='w-3/6 text-end'>
                                                <div className="tablet:flex flex-1 text-xs">{wallet.numbertokens}</div>
                                                <div className="tablet:flex flex-1 text-sm text-destaque flex items-center gap-2 justify-end">${wallet.value} <TbCoins className='icon-menu' /></div>
                                            </div>
                                        </div>
                                    </Link>
                                </div>
                            ))}
                        </div>
                    </div>
                </>
            ) : (
                <>
                    <div className="">
                        <Input
                            type="text"
                            size="large"
                            className="input-geral"
                            placeholder="Search by Wallet Address..."
                            value={searchQuery}
                            onChange={handleInputChange}
                        />
                    </div>
                    {/* <br></br>
                    <div className='flex justify-end'>
                        <button
                            className="sorting-button p-2"
                            onClick={toggleSortOrder}
                        >
                            Value {sortOrder === 'asc' ? '▼' : '▲'}
                        </button>
                    </div> */}
                    <br></br>
                    <div className="w-full">
                        <div className="">
                            <div className="flex mb-5 gap-4 titulosdatabela">
                                <div className="flex-1 py-2 font-semibold text-sm">Position - Wallet</div>
                                <div className="flex-1 py-2 font-semibold text-sm">Best Token Hold</div>
                                <div className="flex-1 py-2 font-semibold text-sm">Nº Tokens</div>
                                <div className="flex-1 py-2 font-semibold text-sm">Value $</div>
                                <div className="flex-1 py-2 font-semibold text-sm">Last 7 Days</div>
                            </div>
                            {searchFilterDay.map((wallet, index) => (
                                <div
                                    key={index}
                                    className={`linha-info mb-4 border-b border-slate-700 items-center cursor-pointer transition-default row-flex items-stretch mb-2 ${index % 2 === 0 ? 'bg-dark-sexta' : 'bg-dark-sexta'}`}
                                >
                                    <Link to={`/transactions`}>
                                        <div className='flex gap-4 items-center justify-between'>
                                            <div className="tablet:flex flex-1 py-2 text-sm">

                                                <Tooltip placement="topRight" title={wallet.wallet}>
                                                    # {wallet.position} - {wallet.wallet.slice(0, 5)}...{wallet.wallet.slice(-5)}
                                                </Tooltip>
                                                <CopyButton text={wallet.wallet} />
                                            </div>
                                            <div className="tablet:flex flex-1 py-2 text-sm">{wallet.besthold}</div>
                                            <div className="tablet:flex flex-1 flex items-center gap-2 py-2 text-sm">{wallet.numbertokens} </div>
                                            <div className="tablet:flex flex-1 flex items-center gap-2 py-2 text-destaque text-sm">$ {wallet.value} <TbCoins className='icon-menu' /></div>
                                            <div className="tablet:flex flex-1 py-2 text-sm"><img src={wallet.imageURL} /></div>
                                        </div>
                                    </Link>

                                </div>
                            ))}
                        </div>
                    </div>
                </>
            )
            }

        </>
    );
}