import React from 'react';
import TabeladeTransactions from '../../data/tabelas/tabeladetransactions';


export default function Transactions({ darkMode, isTabletMobile }) {

    return (
        <div className='w-full'>
            <div className='card'>
                <h2 className='text-2xl font-extrabold'>All Transactions</h2>
                <br></br>
                <p>Explore the top transactions on this page. Use the search to find a specific transaction. Soon, you'll be able to apply additional filters to customize your view and also add this transaction to your favorites list.</p>
                <br></br>
                <TabeladeTransactions isTabletMobile={isTabletMobile} />
            </div>
        </div>
    );
}
