import React from 'react';
import Partners from '../partners';


export default function Footer({ isTabletMobile }) {




    return (
        <div className="footer mt-5">

            <div className='flex flex-col gap-6'>
                {/* <Partners /> */}
                {isTabletMobile ? (
                    // Responsive
                    <div className='text-center'>
                        <p className='text-xs'>© 2023 OPEN WALLET. All Rights Reserved</p>
                    </div>
                ) : (
                    // Normal
                    <div className='text-center'>
                        <p className='text-xs'>© 2023 OPEN WALLET. All Rights Reserved</p>
                    </div>
                )}
            </div>

        </div>
    );
}
