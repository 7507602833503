import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import { createRoot } from 'react-dom';
import App from './App';
import './styles.css';
import './styles/responsive-desktop.css';
import './styles/responsive-mobile.css';
import './styles/white.css';
import './styles/dark.css';
import './styles/index.css';



// Use createRoot instead of ReactDOM.render
const root = createRoot(document.getElementById('root'));
root.render(
    
        <BrowserRouter>
            <App />
        </BrowserRouter>
);
