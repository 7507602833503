import React, { useEffect, useState } from 'react';
import { USERDATA } from '../data/user-data';
import { Tabs, Tooltip } from 'antd';
import CopyButton from '../components/copybutton';
import { TbCoins } from 'react-icons/tb';


const { TabPane } = Tabs;

export default function Perfil({ darkMode, setDarkMode, isTabletMobile }) {
    const [userperfilData, setUserPerfilData] = useState([]);

    useEffect(() => {
        setUserPerfilData(USERDATA.useralldata[0] || []);
    }, []);

    // const toggleDarkMode = () => {
    //     setDarkMode(!darkMode);
    // };

    return (
        <div className={`w-full flex justify-center my-perfil items-center ${darkMode ? 'dark' : 'white'}`}>
            <div className="w-full grid gap-4 grid-cols-1">
                <div class="card">
                    <div class="">
                        <h1 class="text-base title-cinza mb-2">Welcome, <span class="italic">{userperfilData.name}</span></h1>
                        <h1 class="text-2xl font-extrabold">Perfil</h1>
                    </div>
                </div>
                {isTabletMobile ? (
                    <>
                        <div className="card">
                            <div>
                                <Tabs defaultActiveKey="1">
                                    <TabPane tab="My Wallets" key="1">
                                        <div className="">
                                            <div className="flex mb-5 gap-4 titulosdatabela justify-between">
                                                <div>
                                                    <div className="flex-1 py-2 font-semibold text-xs">Name Wallet</div>
                                                    <div className="flex-1 py-2 font-semibold text-xs">Address</div>
                                                </div>
                                                <div>
                                                    <div className="flex-1 py-2 font-semibold text-xs">Value Wallet</div>
                                                    <div className="flex-1 py-2 font-semibold text-xs text-right">Favorite</div>
                                                </div>
                                            </div>
                                            {userperfilData?.mywallet?.map((mywallet, index) => (
                                                <div
                                                    key={index}
                                                    className={`mb-4 border-b border-slate-700 ${index % 2 === 0 ? 'bg-dark-sexta' : 'bg-dark-sexta'}`}
                                                >
                                                    <div className='flex gap-4 items-center justify-between linhasdatabela'>
                                                        <div className='flex flex-col justify-between '>
                                                            <div className="tablet:flex flex-1 text-xs py-2">
                                                                {mywallet.mywalletname}
                                                            </div>
                                                            <div className="tablet:flex flex-1 text-xs text-end py-2">
                                                                <Tooltip placement="topRight" title={mywallet.mywalletaddress}>
                                                                    {mywallet.mywalletaddress.slice(0, 5)}...{mywallet.mywalletaddress.slice(-5)}
                                                                </Tooltip>
                                                                <CopyButton text={mywallet.mywalletaddress} />
                                                            </div>
                                                        </div>
                                                        <div className='flex flex-col justify-between'>
                                                            <div className=''>
                                                                <div className="tablet:flex flex-1 text-xs py-2">$ {mywallet.mywallevalue}</div>
                                                                <div className="tablet:flex flex-1 flex justify-end text-xs py-2"><img className='w-6' src={mywallet.imageURL} /></div>
                                                            </div>
                                                        </div>
                                                    </div>



                                                </div>
                                            ))}
                                        </div>
                                    </TabPane>
                                    <TabPane tab="Favorite Wallets" key="2">
                                        <div className="">
                                            <div className="flex mb-5 gap-4 titulosdatabela justify-between">
                                                <div>
                                                    <div className="flex-1 py-2 font-semibold text-xs">Name Wallet</div>
                                                    <div className="flex-1 py-2 font-semibold text-xs">Address</div>
                                                </div>
                                                <div>
                                                    <div className="flex-1 py-2 font-semibold text-xs">Value Wallet</div>
                                                    <div className="flex-1 py-2 font-semibold text-xs text-right">Favorite</div>
                                                </div>
                                            </div>
                                            {userperfilData?.favoriteswallets?.map((mywallet, index) => (
                                                <div
                                                    key={index}
                                                    className={`mb-4 border-b border-slate-700 ${index % 2 === 0 ? 'bg-dark-sexta' : 'bg-dark-sexta'}`}
                                                >
                                                    <div className='flex gap-4 items-center justify-between linhasdatabela'>
                                                        <div className='flex flex-col justify-between '>
                                                            <div className="tablet:flex flex-1 text-xs py-2">
                                                                {mywallet.walletfavname}
                                                            </div>
                                                            <div className="tablet:flex flex-1 text-xs text-end py-2">
                                                                <Tooltip placement="topRight" title={mywallet.walletfavaddress}>
                                                                    {mywallet.walletfavaddress.slice(0, 5)}...{mywallet.walletfavaddress.slice(-5)}
                                                                </Tooltip>
                                                                <CopyButton text={mywallet.walletfavaddress} />
                                                            </div>
                                                        </div>
                                                        <div className='flex flex-col justify-between'>
                                                            <div className=''>
                                                                <div className="tablet:flex flex-1 text-xs py-2">$ {mywallet.mywallevalue}</div>
                                                                <div className="tablet:flex flex-1 flex justify-end text-xs py-2"><img className='w-6' src={mywallet.imageURL} /></div>
                                                            </div>
                                                        </div>
                                                    </div>



                                                </div>
                                            ))}
                                        </div>
                                    </TabPane>
                                    <TabPane tab="Favorite Tokens" key="3">
                                        <div className="">
                                            <div className="flex mb-5 gap-4 titulosdatabela justify-between">
                                                <div>
                                                    <div className="flex-1 py-2 font-semibold text-xs">Name Wallet</div>
                                                    <div className="flex-1 py-2 font-semibold text-xs">Address</div>
                                                </div>
                                                <div>
                                                    <div className="flex-1 py-2 font-semibold text-xs">Value Wallet</div>
                                                    <div className="flex-1 py-2 font-semibold text-xs text-right">Favorite</div>
                                                </div>
                                            </div>
                                            {userperfilData?.favoritestokens?.map((mywallet, index) => (
                                                <div
                                                    key={index}
                                                    className={`mb-4 border-b border-slate-700 ${index % 2 === 0 ? 'bg-dark-sexta' : 'bg-dark-sexta'}`}
                                                >
                                                    <div className='flex gap-4 items-center justify-between linhasdatabela'>
                                                        <div className='flex flex-col justify-between '>
                                                            <div className="tablet:flex flex-1 text-xs py-2">
                                                                {mywallet.tokenfavname}
                                                            </div>
                                                            <div className="tablet:flex flex-1 text-xs text-end py-2">
                                                                <Tooltip placement="topRight" title={mywallet.tokenfavaddress}>
                                                                    {mywallet.tokenfavaddress.slice(0, 5)}...{mywallet.tokenfavaddress.slice(-5)}
                                                                </Tooltip>
                                                                <CopyButton text={mywallet.tokenfavaddress} />
                                                            </div>
                                                        </div>
                                                        <div className='flex flex-col justify-between'>
                                                            <div className=''>
                                                                <div className="tablet:flex flex-1 text-xs py-2">$ {mywallet.tokenfavvalue}</div>
                                                                <div className="tablet:flex flex-1 flex justify-end text-xs py-2"><img className='w-6' src={mywallet.imageURL} /></div>
                                                            </div>
                                                        </div>
                                                    </div>



                                                </div>
                                            ))}
                                        </div>
                                    </TabPane>
                                </Tabs>
                            </div>

                        </div>
                        {/* <div className="card">
                            <button className='togglemodetheme' onClick={toggleDarkMode}>
                                {darkMode ? <IoMdSunny /> : <IoMdMoon />}
                            </button>
                        </div> */}
                    </>
                ) : (
                    <>
                        <div className="card">
                            <div>
                                <Tabs defaultActiveKey="1">
                                    <TabPane tab="My Wallets" key="1">
                                        <div className="">
                                            <div className="flex mb-5 gap-4 titulosdatabela">
                                                <div className="flex-1 py-2 font-semibold text-sm">Name Wallet</div>
                                                <div className="flex-1 py-2 font-semibold text-sm">Address</div>
                                                <div className="flex-1 py-2 font-semibold text-sm">$ Value Wallet</div>
                                                <div className="flex-1 py-2 font-semibold text-sm text-right">Favorite</div>
                                            </div>
                                            {userperfilData?.mywallet?.map((mywallet, index) => (
                                                <div
                                                    key={index}
                                                    className={`mb-4 border-b border-slate-700 items-center cursor-pointer transition-default row-flex items-stretch mb-2 ${index % 2 === 0 ? 'bg-dark-sexta' : 'bg-dark-sexta'}`}
                                                >
                                                    <div className='linha-info flex gap-4 items-center justify-between h-9'>
                                                        <div className="tablet:flex flex-1 py-2 text-sm">{mywallet.mywalletname}</div>
                                                        <div className="tablet:flex flex-1 py-2 text-sm">
                                                            <Tooltip placement="topRight" title={mywallet.mywalletaddress}>
                                                                {mywallet.mywalletaddress.slice(0, 5)}...{mywallet.mywalletaddress.slice(-5)}
                                                            </Tooltip>
                                                            <CopyButton text={mywallet.mywalletaddress} />
                                                        </div>
                                                        <div className="tablet:flex flex-1 flex items-center gap-2 py-2 text-sm text-destaque">${mywallet.mywallevalue} <TbCoins className='icon-menu' /></div>
                                                        <div className="tablet:flex flex-1 py-2 text-sm flex justify-end"><img className='w-6' src={mywallet.imageURL} /></div>
                                                    </div>
                                                </div>
                                            ))}
                                        </div>
                                    </TabPane>
                                    <TabPane tab="Favorite Wallets" key="2">
                                        <div className="">
                                            <div className="flex mb-5 gap-4 titulosdatabela">
                                                <div className="flex-1 py-2 font-semibold text-sm">Name Wallet</div>
                                                <div className="flex-1 py-2 font-semibold text-sm">Address</div>
                                                <div className="flex-1 py-2 font-semibold text-sm">$ Value Wallet</div>
                                                <div className="flex-1 py-2 font-semibold text-sm text-right">Favorite</div>
                                            </div>
                                            {userperfilData?.favoriteswallets?.map((mywallet, index) => (
                                                <div
                                                    key={index}
                                                    className={`mb-4 border-b border-slate-700 items-center cursor-pointer transition-default row-flex items-stretch mb-2 ${index % 2 === 0 ? 'bg-dark-sexta' : 'bg-dark-sexta'}`}
                                                >
                                                    <div className='linha-info flex gap-4 items-center justify-between h-9'>
                                                        <div className="tablet:flex flex-1 py-2 text-sm">{mywallet.walletfavname}</div>
                                                        <div className="tablet:flex flex-1 py-2 text-sm">
                                                            <Tooltip placement="topRight" title={mywallet.walletfavaddress}>
                                                                {mywallet.walletfavaddress.slice(0, 5)}...{mywallet.walletfavaddress.slice(-5)}
                                                            </Tooltip>
                                                            <CopyButton text={mywallet.walletfavaddress} />
                                                        </div>
                                                        <div className="tablet:flex flex-1 flex items-center gap-2 py-2 text-sm text-destaque">${mywallet.mywallevalue} <TbCoins className='icon-menu' /></div>
                                                        <div className="tablet:flex flex-1 py-2 text-sm flex justify-end"><img className='w-6' src={mywallet.imageURL} /></div>
                                                    </div>
                                                </div>
                                            ))}
                                        </div>
                                    </TabPane>
                                    <TabPane tab="Favorite Tokens" key="3">
                                        <div className="">
                                            <div className="flex mb-5 gap-4 titulosdatabela">
                                                <div className="flex-1 py-2 font-semibold text-sm">Name Wallet</div>
                                                <div className="flex-1 py-2 font-semibold text-sm">Address</div>
                                                <div className="flex-1 py-2 font-semibold text-sm">$ Value Wallet</div>
                                                <div className="flex-1 py-2 font-semibold text-sm text-right">Favorite</div>
                                            </div>
                                            {userperfilData?.favoritestokens?.map((mywallet, index) => (
                                                <div
                                                    key={index}
                                                    className={`mb-4 border-b border-slate-700 items-center cursor-pointer transition-default row-flex items-stretch mb-2 ${index % 2 === 0 ? 'bg-dark-sexta' : 'bg-dark-sexta'}`}
                                                >
                                                    <div className='linha-info flex gap-4 items-center justify-between h-9'>
                                                        <div className="tablet:flex flex-1 py-2 text-sm">{mywallet.tokenfavname}</div>
                                                        <div className="tablet:flex flex-1 py-2 text-sm">
                                                            <Tooltip placement="topRight" title={mywallet.tokenfavaddress}>
                                                                {mywallet.tokenfavaddress.slice(0, 5)}...{mywallet.tokenfavaddress.slice(-5)}
                                                            </Tooltip>
                                                            <CopyButton text={mywallet.tokenfavaddress} />
                                                        </div>
                                                        <div className="tablet:flex flex-1 flex items-center gap-2 py-2 text-sm text-destaque">${mywallet.tokenfavvalue} <TbCoins className='icon-menu' /></div>
                                                        <div className="tablet:flex flex-1 py-2 text-sm flex justify-end"><img className='w-6' src={mywallet.imageURL} /></div>
                                                    </div>
                                                </div>
                                            ))}
                                        </div>
                                    </TabPane>
                                </Tabs>
                            </div>
                        </div>
                    </>
                )}
            </div>
        </div>
    );
}
