import React, { useState } from 'react';
import {
    Routes,
    Route
} from "react-router-dom";
import Inicio from "./routes/inicio";
import Perfil from './routes/perfil';
import Transactions from './routes/transactions/transactions';
import Wallets from './routes/wallets/wallets';
import Tokens from './routes/tokens/tokens';
import TokensPerfil from './routes/tokens/tokens-perfil';

export function AppRoutes({ isTabletMobile, darkMode, setDarkMode }) {


    return (

            <Routes>

                <Route exact path="/" element={<Inicio isTabletMobile={isTabletMobile} darkMode={darkMode} />} />
                <Route exact path="/transactions" element={<Transactions isTabletMobile={isTabletMobile} darkMode={darkMode}  />} />
                <Route exact path="/best-holders" element={<Wallets isTabletMobile={isTabletMobile} darkMode={darkMode}  />} />
                <Route exact path="/tokens" element={<Tokens isTabletMobile={isTabletMobile} darkMode={darkMode}  />} />
                {/* <Route exact path="/tokens/:id" element={<TokensPerfil isTabletMobile={isTabletMobile} darkMode={darkMode}  />} /> */}
                <Route exact path="/perfil" element={<Perfil isTabletMobile={isTabletMobile} darkMode={darkMode} setDarkMode={setDarkMode}  />} />

            </Routes>
    )
}
