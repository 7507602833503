import React, { useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import logoDark from '../../assets/logo1.svg';
import logoLight from '../../assets/logolight.svg';
import logoiconDark from '../../assets/logoicon.svg';
import logoiconLight from '../../assets/logoiconlight.svg';
import { HomeOutlined, UserOutlined } from '@ant-design/icons';
import { RiMenuFoldLine, RiMenuUnfoldFill, RiMenuUnfoldLine } from 'react-icons/ri';
import { TbCoinBitcoin, TbCoins } from 'react-icons/tb';
import { BiTransfer, BiWalletAlt } from 'react-icons/bi';
import { IoMdSunny, IoMdMoon } from 'react-icons/io';


export default function SidebarHeader({ sidebarVisible, setSidebarVisible, setDarkMode, darkMode }) {
    const location = useLocation();

    const toggleDarkMode = () => {
        setDarkMode(!darkMode);
    };

    const toggleSidebar = () => {
        setSidebarVisible(!sidebarVisible);
    };

    return (
        <div className={`sidebar-header ${sidebarVisible ? 'sidebar-visivel' : 'sidebar-escondida'} ${darkMode ? 'dark' : 'white'}`}>
            {sidebarVisible ? (
                <div className='flex flex-col gap-8'>
                    <div className='logotipo'>
                        <Link to="/">
                            <img src={darkMode ? logoDark : logoLight} alt="OPENWALLET" />
                        </Link>
                    </div>
                    <div>
                        <nav>
                            <ul className='menu-main text-base font-bold leading-loose'>
                                <Link to="/">
                                    <li className={`menu-item h-10 pl-2 flex items-center gap-4 font-medium ${location.pathname === '/' ? 'active' : ''}`}>
                                        <HomeOutlined className='icon-menu' /> Home
                                    </li>
                                </Link>
                                <Link to="/tokens">
                                    <li className={`menu-item h-10 pl-2 flex items-center gap-4 font-medium ${location.pathname === '/tokens' ? 'active' : ''}`}>
                                        <TbCoinBitcoin className='icon-menu' /> Tokens
                                    </li>
                                </Link>
                                <Link to="/best-holders">
                                    <li className={`menu-item h-10 pl-2 flex items-center gap-4 font-medium ${location.pathname === '/best-holders' ? 'active' : ''}`}>
                                        <TbCoins className='icon-menu' /> Best Holders
                                    </li>
                                </Link>
                                {/* <Link to="/wallets">
                                    <li className={`menu-item h-10 pl-2 flex items-center gap-4 font-medium ${location.pathname === '/wallets' ? 'active' : ''}`}>
                                        <BiWalletAlt className='icon-menu' /> Wallets
                                    </li>
                                </Link> */}
                                <Link to="/transactions">
                                    <li className={`menu-item h-10 pl-2 flex items-center gap-4 font-medium ${location.pathname === '/transactions' ? 'active' : ''}`}>
                                        <BiTransfer className='icon-menu' /> Transactions
                                    </li>
                                </Link>
                                <Link to="/perfil">
                                    <li className={`menu-item h-10 pl-2 flex items-center gap-4 font-medium ${location.pathname === '/perfil' ? 'active' : ''}`}>
                                        <UserOutlined className='icon-menu' /> Perfil
                                    </li>
                                </Link>
                            </ul>
                        </nav>
                    </div>
                    <div className='btn-sidebar-config flex flex-col gap-4'>
                        <button className="toggle-sidebar-button icons-section-pai" onClick={toggleSidebar}>
                            {sidebarVisible ? (
                                <RiMenuFoldLine className='icons-menu' />

                            ) : (
                                <RiMenuUnfoldFill className='icons-menu' />
                            )}
                        </button>
                        <button className='togglemodetheme' onClick={toggleDarkMode}>
                            {darkMode ? <IoMdSunny /> : <IoMdMoon />}
                        </button>
                    </div>
                </div>
            ) : (
                <div className='flex flex-col gap-8 items-center'>
                    <div className='logotipo'>
                        <Link to="/">
                            <img src={darkMode ? logoiconDark : logoiconLight} alt="OPENWALLET" />
                        </Link>
                    </div>
                    <div className='flex flex-col gap-8 w-full'>
                        <nav>
                            <ul className='menu-main text-base font-bold leading-loose'>
                                <Link to="/">
                                    <li className={`menu-item h-10 pl-2 flex items-center gap-4 font-medium ${location.pathname === '/' ? 'active' : ''}`}>
                                        <HomeOutlined className='icon-menu' />
                                    </li>
                                </Link>
                                <Link to="/tokens">
                                    <li className={`menu-item h-10 pl-2 flex items-center gap-4 font-medium ${location.pathname === '/tokens' ? 'active' : ''}`}>
                                        <TbCoinBitcoin className='icon-menu' />
                                    </li>
                                </Link>
                                <Link to="/best-holders">
                                    <li className={`menu-item h-10 pl-2 flex items-center gap-4 font-medium ${location.pathname === '/best-holders' ? 'active' : ''}`}>
                                        <TbCoins className='icon-menu' />
                                    </li>
                                </Link>
                                {/* <Link to="/wallets">
                                    <li className={`menu-item h-10 pl-2 flex items-center gap-4 font-medium ${location.pathname === '/wallets' ? 'active' : ''}`}>
                                        <BiWalletAlt className='icon-menu' /> Wallets
                                    </li>
                                </Link> */}
                                <Link to="/transactions">
                                    <li className={`menu-item h-10 pl-2 flex items-center gap-4 font-medium ${location.pathname === '/transactions' ? 'active' : ''}`}>
                                        <BiTransfer className='icon-menu' />
                                    </li>
                                </Link>

                                <Link to="/perfil">
                                    <li className={`menu-item h-10 pl-2 flex items-center gap-4 font-medium ${location.pathname === '/perfil' ? 'active' : ''}`}>
                                        <UserOutlined className='icon-menu' />
                                    </li>
                                </Link>
                            </ul>
                        </nav>
                    </div>
                    <div className='btn-sidebar-config flex flex-col gap-4'>
                        <button className="toggle-sidebar-button icons-section-pai" onClick={toggleSidebar}>
                            {sidebarVisible ? (
                                <RiMenuFoldLine className='icons-menu' />

                            ) : (
                                <RiMenuUnfoldLine className='icons-menu' />
                            )}
                        </button>
                        <button className='togglemodetheme' onClick={toggleDarkMode}>
                            {darkMode ? <IoMdSunny /> : <IoMdMoon />}
                        </button>
                    </div>
                </div>
            )}
        </div>
    );
}
