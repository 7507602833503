
export const WALLETSDATA = {
    walletsall: [
        {
            "position": "1",
            "wallet": "0x5A81bEcD93F3477dD19A8aE7b42EeBb2cAb963a7",
            "besthold": "XRP",
            "numbertokens": "37458",
            "value": "18,860.49",
            "imageURL": require("../assets/verde-up.svg").default
        },
        {
            "position": "2",
            "wallet": "0x8D7cAbF7024c3f70b29cE2f78bFE3D614A90348e",
            "besthold": "XRP",
            "numbertokens": "23454",
            "value": "11,800.50",
            "imageURL": require("../assets/red-down.svg").default

        },
        {
            "position": "3",
            "wallet": "0x8D614A90348e7cAbF7024c3f70b29cE2f78bFE3D",
            "besthold": "XRP",
            "numbertokens": "19408",
            "value": "9,787.65",
            "imageURL": require("../assets/red-down.svg").default

        },
        {
            "position": "4",
            "wallet": "0x8D614A90F7024c3f70348e7cAbb2bFE3D9cE2f78",
            "besthold": "XRP",
            "numbertokens": "17458",
            "value": "8,802.49",
            "imageURL": require("../assets/red-down.svg").default

        },
        {
            "position": "5",
            "wallet": "0xf9f0737C734dDaF5508d41f0A6A033Fa1a7f717F",
            "besthold": "XRP",
            "numbertokens": "14345",
            "value": "8,805.19",
            "imageURL": require("../assets/red-down.svg").default

        },
        {
            "position": "6",
            "wallet": "0x6B7FdC8A2F9e2a18C5Bf4F37f56a9f4A8c9D7D3E",
            "besthold": "XRP",
            "numbertokens": "7458",
            "value": "3,753.95",
            "imageURL": require("../assets/verde-up.svg").default

        },
        {
            "position": "7",
            "wallet": "0xA2eFbD1f6546cC8e97c75D4f51Ac71d34bCDb47C",
            "besthold": "XRP",
            "numbertokens": "140",
            "value": "70.59",
            "imageURL": require("../assets/red-down.svg").default

        },
    ]
}