import React, { useState } from 'react';
import { BiCopy, BiCheck } from 'react-icons/bi';

function CopyButton({ text }) {
  const [copied, setCopied] = useState(false);

  const handleCopyClick = () => {
    navigator.clipboard.writeText(text)
      .then(() => {
        setCopied(true);
        setTimeout(() => {
          setCopied(false);
        }, 1500); 
      })
      .catch(err => console.error('Failed to copy:', err));
  };

  return (
    <button onClick={handleCopyClick} className="">
      {copied ? (
        <div>
          <BiCheck className="ml-2" />
        </div>
      ) : (
        <>
          <BiCopy className="ml-2" />
        </>
      )}
    </button>
  );
}

export default CopyButton;
