import React, { useState } from 'react';
import Header from './components/layout/header';
import SidebarHeader from './components/layout/sidebar-header';
import { AppRoutes } from './Routes';
import { useMediaQuery } from 'react-responsive';
import './styles.css';
import './styles/responsive-desktop.css';
import './styles/responsive-mobile.css';
import './styles/index.css';
import './styles/dark.css';
import './styles/white.css';
import BottomMenu from './components/layout/bottom-menu';
import Footer from './components/layout/footer';
import Popup from './components/popup-info';


function App() {
  // Responsive
  const isTabletMobile = useMediaQuery({
    query: '(max-width: 1024px)'
  });


  const [sidebarVisible, setSidebarVisible] = useState(false); // Estado para controlar a  sidebar
  const [darkMode, setDarkMode] = useState(true);
  const [getjogador, setGetJogador] = useState(false);
  const [isPopupOpen, setIsPopupOpen] = useState(false);


  
  return (
    <div className={`main-sec-all ${darkMode ? 'dark' : 'white'}`}>
      {isTabletMobile ? (
        // Responsive
        <div className={`p-4 ${isTabletMobile ? 'h-screen' : 'h-screen'}`}>
          <div className="main-content gap-4 flex flex-col mobile-main-sec">
            <Header setDarkMode={setDarkMode} isPopupOpen={isPopupOpen} setIsPopupOpen={setIsPopupOpen} darkMode={darkMode} isTabletMobile={isTabletMobile} sidebarVisible={sidebarVisible} setSidebarVisible={setSidebarVisible} />
            <div className="overflow-auto flex flex-col gap-4 pr-4">
              <AppRoutes isTabletMobile={isTabletMobile} darkMode={darkMode} setDarkMode={setDarkMode} />
              <Footer />
            </div>
          </div>
          <div className='fixed bottom-4 main-content menu-mobile shadow-md'>
            <BottomMenu darkMode={darkMode} />
          </div>
        </div>
      ) : (
        // Normal
        <div className="p-4 flex flex-row gap-4 h-screen text-primary">
          <div>
            <SidebarHeader setDarkMode={setDarkMode} darkMode={darkMode} sidebarVisible={sidebarVisible} setSidebarVisible={setSidebarVisible} />
          </div>
          <div className={`main-content flex flex-col sidebar-header ${sidebarVisible ? 'sidebar-visivel' : 'sidebar-escondida'}`}>
            <Header getjogador={getjogador} setGetJogador={setGetJogador} sidebarVisible={sidebarVisible} setSidebarVisible={setSidebarVisible} />
            <div className="overflow-auto">
              <div className="basis-1/2 flex flex-col gap-4 h-full pr-4">
                <AppRoutes isTabletMobile={isTabletMobile} darkMode={darkMode} setGetJogador={setGetJogador} />
                <Footer />
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default App;
