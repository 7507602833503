import React from 'react';
import TabeladeWallets from '../../data/tabelas/tabeladewallets';


export default function Wallets({ darkMode, isTabletMobile }) {

    return (
        <div className='w-full'>
            <div className='card'>
                <h2 className='text-2xl font-extrabold'>Top Wallets</h2>
                <br></br>
                <h2 className='text-lg font-medium'>Coin selected: <span className='text-destaque uppercase'>XRP</span></h2>
                <br></br>
                <p>Access essential information in a simplified manner about the big whales of the <span className='text-destaque'>XRP</span> token.</p>
                <br></br>
                <TabeladeWallets isTabletMobile={isTabletMobile}/>
            </div>
        </div>
    );
}
