export const TOKENSDATAWIDGET = {
  tokenall: [
    {
      "id": 1,
      "nametoken": "Bitcoin",
      "addresstoken": "0x5A81bEcD93F3477dD19A8aE7b42EeBb2cAb963a7",
      "price": "$25,999.44",
      "marketcap": "$501,592,049,465",
      "imageURL": require("../assets/verde-up.svg").default,
      "numberholders": "423.562",
    },
    {
      "id": 2,
      "nametoken": "Ethereum",
      "addresstoken": "0x3F9cE7A86aD8f5f1d9BcB6EfD40E761abfE2F2C3",
      "price": "$1,639.61",
      "marketcap": "$195,226,498,365",
      "imageURL": require("../assets/verde-up.svg").default,
      "numberholders": "122.312",
    },
    {
      "id": 3,
      "nametoken": "Tether",
      "addresstoken": "0x8D614A90348e7cAbF7024c3f70b29cE2f78bFE3D",
      "price": "$0.9999",
      "marketcap": "$82,876,519,550",
      "imageURL": require("../assets/red-down.svg").default,
      "numberholders": "1.243.543",
    },
    {
      "id": 4,
      "nametoken": "BNB",
      "addresstoken": "0xA2eFbD1f6546cC8e97c75D4f51Ac71d34bCDb47C",
      "price": "$214.28",
      "marketcap": "$33,095,193,330",
      "imageURL": require("../assets/red-down.svg").default,
      "numberholders": "32.423",
    },
    {
      "id": 5,
      "nametoken": "XRP",
      "addresstoken": "0x6B7FdC8A2F9e2a18C5Bf4F37f56a9f4A8c9D7D3E",
      "price": "$0.5062",
      "marketcap": "$26,866,466,604",
      "imageURL": require("../assets/verde-up.svg").default,
      "numberholders": "121.221",
    },
  ],
};
