import React from 'react';
import { Link, useLocation  } from 'react-router-dom';
import { HomeOutlined, UserOutlined } from '@ant-design/icons';
import { TbCoinBitcoin, TbCoins } from 'react-icons/tb';
import { BiTransfer } from 'react-icons/bi';

export default function BottomMenu({darkMode}) {

  const location = useLocation();

  return (
    <nav className="flex p-4 justify-between rounded-lg menu-bottom-mobile">
      <Link to="/" className={`menu-item flex flex-col items-center p-2 ${location.pathname === '/' ? 'active' : ''}`}>
        <HomeOutlined className='icon-menu' />
      </Link>
      <Link to="/tokens" className={`menu-item flex flex-col items-center p-2 ${location.pathname === '/tokens' ? 'active' : ''}`}>
        <TbCoinBitcoin className='icon-menu' />
      </Link>
      <Link to="/best-holders" className={`menu-item flex flex-col items-center p-2 ${location.pathname === '/best-holders' ? 'active' : ''}`}>
        <TbCoins className='icon-menu' /> 
      </Link>
      <Link to="/transactions" className={`menu-item flex flex-col items-center p-2 ${location.pathname === '/transactions' ? 'active' : ''}`}>
        <BiTransfer className='icon-menu' />
      </Link>

      <Link to="/perfil" className={`menu-item flex flex-col items-center p-2 ${location.pathname === '/perfil' ? 'active' : ''}`}>
        <UserOutlined className='icon-menu' />
      </Link>
    </nav>
  );
}


