import React from 'react';
import TabeladeTokens from '../../data/tabelas/tabeladetokens';


export default function Tokens({ darkMode, isTabletMobile }) {
    

    return (
        <div className='w-full'>
            <div className='card'>
                <h2 className='text-2xl font-extrabold'>Top Tokens</h2>
                <br></br>
                <p>Browse the list of tokens below to gain insights into real-time transaction history and more. We've streamlined token management to help you make informed decisions and maximize your cryptocurrency investments.</p>
                <br></br>
                <TabeladeTokens isTabletMobile={isTabletMobile} />
            </div>
        </div>
    );
}
