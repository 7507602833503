export const USERDATA = {
    useralldata: [
      {
        "id": 1,
        "name": "John Fox",
        "email": "johnfox@gmail.com",
        "password": "johnfox",
        "mywallet": [
          {
            "mywalletname": "MyWallet1",
            "mywalletaddress": "0x5A81bEcD93F3477dD19A8aE7b42EeBb2cAb963a7",
            "mywallevalue": "18,860.49",
            "mywalletwatch": 0,
            "imageURL": require("../assets/star (1).svg").default
          },
          {
            "mywalletname": "MyWallet2",
            "mywalletaddress": "0x8D7cAbF7024c3f70b29cE2f78bFE3D614A90348e",
            "mywallevalue": "11,800.50",
            "mywalletwatch": 0,
            "imageURL": require("../assets/star (2).svg").default
          },
        ],
        "favoriteswallets": [
          {
            "walletfavname": "WalletFav1",
            "walletfavaddress": "0x8D614A90348e7cAbF7024c3f70b29cE2f78bFE3D",
            "mywallevalue": "9,787.65",
            "mywalletwatch": 0,
            "imageURL": require("../assets/star (2).svg").default
          },
          {
            "walletfavname": "WalletFav2",
            "walletfavaddress": "0x8D614A90F7024c3f70348e7cAbb2bFE3D9cE2f78",
            "mywallevalue": "8,802.49",
            "mywalletwatch": 0,
            "imageURL": require("../assets/star (1).svg").default
          }
        ],
        "favoritestokens": [
          {
            "tokenfavname": "XRP",
            "tokenfavaddress": "0xf9f0737C734dDaF5508d41f0A6A033Fa1a7f717F",
            "tokenfavvalue": "8,805.19",
            "tokenfavwatch": 0,
            "imageURL": require("../assets/star (1).svg").default
          },
          {
            "tokenfavname": "BTC",
            "tokenfavaddress": "0x6B7FdC8A2F9e2a18C5Bf4F37f56a9f4A8c9D7D3E",
            "tokenfavvalue": "3,753.95",
            "tokenfavwatch": 0,
            "imageURL": require("../assets/star (2).svg").default
          }
        ],
      },
    ],
  };
  