import React, { useEffect, useState } from 'react';
import { Input, Tooltip } from 'antd';
import { TRANSACTIONSDATA } from '../obter-dados-transactions';
import CopyButton from '../../components/copybutton';
import { TbCoins } from 'react-icons/tb';

export default function TabeladeTransactions({ darkMode, isTabletMobile }) {

    const [searchQuery, setSearchQuery] = useState('');
    const [transactionsData, setTransactionsData] = useState([]);

    useEffect(() => {
        setTransactionsData(TRANSACTIONSDATA.transactionsall || []);
    }, []);

    const searchFilterDay = transactionsData.filter((transaction) => {
        const fullName = transaction.from;
        const searchQueryLowerCase = searchQuery.toLowerCase();
        return fullName.toLowerCase().includes(searchQueryLowerCase);
    });

    const handleInputChange = (event) => {
        setSearchQuery(event.target.value);
    };

    return (
        <>
            {isTabletMobile ? (
                // Responsive
                <>
                    <div className="">
                        <Input
                            type="text"
                            size="large"
                            className="input-geral"
                            placeholder="Search by Transaction..."
                            value={searchQuery}
                            onChange={handleInputChange}
                        />
                    </div>
                    <br></br>
                    <div className="w-full">
                        <div className="">
                            <div className="flex mb-5 gap-4 titulosdatabela justify-between">
                                <div>
                                    <div className="flex-1 font-semibold text-xs p-0 m-0">From</div>
                                    <div className="flex-1 font-semibold text-xs p-0 m-0">Date</div>
                                    <div className="flex-1 font-semibold text-xs p-0 m-0">Hour</div>
                                </div>
                                <div>
                                    <div className="flex-1 font-semibold text-xs text-right p-0 m-0">To</div>
                                    <div className="flex-1 font-semibold text-xs text-right p-0 m-0">Nº Tokens</div>
                                    <div className="flex-1 font-semibold text-xs text-right p-0 m-0">$ Value</div>
                                </div>
                            </div>
                            {searchFilterDay.map((transaction, index) => (
                                <div
                                    key={index}
                                    className={`mb-4 border-b border-slate-700 ${index % 2 === 0 ? 'bg-dark-sexta' : 'bg-dark-sexta'}`}
                                >
                                    <div className='flex flex-col items-flex-start justify-between linhasdatabela'>
                                        <div className='flex flex-wrap justify-between'>
                                            <div className="tablet:flex flex-1 text-xs">
                                                <Tooltip placement="topRight" title={transaction.from}>
                                                    {transaction.from.slice(0, 5)}...{transaction.from.slice(-5)}
                                                </Tooltip>
                                                <CopyButton text={transaction.from} />
                                            </div>
                                            <span>-</span>
                                            <div className="tablet:flex flex-1 text-xs text-end">
                                                <Tooltip placement="topRight" title={transaction.to}>
                                                    {transaction.to.slice(0, 5)}...{transaction.to.slice(-5)}
                                                </Tooltip>
                                                <CopyButton text={transaction.to} />
                                            </div>

                                        </div>
                                        <div className='flex flex-wrap justify-between'>
                                            <div className='w-3/6'>
                                                <div className="tablet:flex flex-1 text-xs py-2">{transaction.date}</div>
                                                <div className="tablet:flex flex-1 text-xs py-2">{transaction.hour}</div>
                                            </div>
                                            <div className='w-3/6 text-end'>
                                                <div className="tablet:flex flex-1 flex items-center gap-2 py-2 text-xs justify-end">{transaction.numbertoken} <TbCoins className='icon-menu' /></div>
                                                <div className="tablet:flex flex-1 text-sm text-destaque flex items-center gap-2 justify-end">${transaction.value} <TbCoins className='icon-menu' /></div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                </>
            ) : (
                <>
                    <div className="">
                        <Input
                            type="text"
                            size="large"
                            className="input-geral"
                            placeholder="Search by Transaction..."
                            value={searchQuery}
                            onChange={handleInputChange}
                        />
                    </div>
                    <br></br>
                    <div className="w-full">
                        <div className="">
                            <div className="flex mb-5 gap-4 titulosdatabela">
                                <div className="flex-1 py-2 font-semibold text-sm">Hash</div>
                                <div className="flex-1 py-2 font-semibold text-sm">Date</div>
                                <div className="flex-1 py-2 font-semibold text-sm">Hour</div>
                                <div className="flex-1 py-2 font-semibold text-sm">From</div>
                                <div className="flex-1 py-2 font-semibold text-sm">To</div>
                                <div className="flex-1 py-2 font-semibold text-sm">Nº Tokens</div>
                                <div className="flex-1 py-2 font-semibold text-sm text-right">$ Value</div>
                            </div>
                            {searchFilterDay.map((transaction, index) => (
                                <div
                                    key={index}
                                    className={`linha-info mb-4 border-b border-slate-700 items-center cursor-pointer transition-default row-flex items-stretch mb-2 ${index % 2 === 0 ? 'bg-dark-sexta' : 'bg-dark-sexta'}`}
                                >
                                    <div className='flex gap-4 items-center justify-between'>
                                        <div className="tablet:flex flex-1 py-2 text-sm">
                                            <Tooltip placement="topRight" title={transaction.hash}>
                                                {transaction.hash.slice(0, 5)}...{transaction.hash.slice(-5)}
                                            </Tooltip>
                                            <CopyButton text={transaction.hash} />
                                        </div>
                                        <div className="tablet:flex flex-1 py-2 text-sm">{transaction.date}</div>
                                        <div className="tablet:flex flex-1 py-2 text-sm">{transaction.hour}</div>
                                        <div className="tablet:flex flex-1 py-2 text-sm">
                                            <Tooltip placement="topRight" title={transaction.from}>
                                                {transaction.from.slice(0, 5)}...{transaction.from.slice(-5)}
                                            </Tooltip>
                                            <CopyButton text={transaction.from} />
                                        </div>
                                        <div className="tablet:flex flex-1 py-2 text-sm">
                                            <Tooltip placement="topRight" title={transaction.to}>
                                                {transaction.to.slice(0, 5)}...{transaction.to.slice(-5)}
                                            </Tooltip>
                                            <CopyButton text={transaction.to} />
                                        </div>
                                        <div className="tablet:flex flex-1 flex items-center gap-2 py-2 text-sm">{transaction.numbertoken} <TbCoins className='icon-menu' /></div>
                                        <div className="tablet:flex flex-1 flex items-center justify-end gap-2 py-2 text-destaque text-right">${transaction.value} <TbCoins className='icon-menu' /></div>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                </>
            )}



        </>
    );
}
